@import "./../variables";
@import "./../mixins";


.terms,
.privacy {
  padding: $terms-margin 24px;
}

.terms {
  ol li:not(:first-child) {
    margin-top: 20px;
  }
}

.terms-card {
  display: flex;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  height: 100%;
  padding: 60px 60px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;

  border-radius: 40px;
  border: 3px solid rgba(255, 255, 255, 0.49);
  background: rgba(255, 255, 255, 0.04);
  box-shadow: 20px 20px 39px 0 rgba(0, 0, 0, 0.64);
  backdrop-filter: blur(12.5px);

  @media (max-width: $screen-md-max) {
    padding: 40px 40px 0;
    gap: 18px;
    border-radius: 28px;
  }

  @media (max-width: $screen-sm-max) {
    padding: 24px 24px 0;
    gap: 16px;
    border-radius: 18px;
  }

  &__title {
    color: $main-color;
    @include title-font();
    font-size: 48px;
    line-height: 120%; /* 57.6px */

    //@media (max-width: $screen-lg-max) {
    //  font-size: 40px;
    //}

    @media (max-width: $screen-sm-max) {
      font-size: 30px;
    }
  }

  &__text {
    @include main-font();
    color: $pure-white;
    font-size: 12px;
    line-height: 180%; /* 21.6px */
    letter-spacing: -0.36px;
    //min-height: 100%;

    th, td {
      vertical-align: top;
    }

    a {
      color: $pure-white !important;
    }

    & > :nth-child(1) {
      //overflow-y: scroll !important;
      overflow-x: hidden !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      @media (max-width: $screen-md-max) {
        margin-right: 0 !important;
      }
    }


    & > :nth-child(1) > :nth-child(1) {
      margin-top: 0 !important;
    }


    @media (max-width: $screen-sm-max) {
      font-size: 10px;
    }

    & > :nth-child(1) > * {
      margin: 20px 0;
    }

    .list--letter {
      list-style-type: lower-alpha;
    }
  }
}

.back-link {
  width: 24px;
  height: 20px;
  background: url("./../../../public/images/arrow.svg") no-repeat center;
  background-size: cover;
  position: absolute;
  left: 40px;
  top: 40px;
}
