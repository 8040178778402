/* Colors */
$pure-white: #ffffff;
$pure-black: #000000;
$bg-color: #030303;
$main-color: #F2B204;
$gray-color: #999B9F;

$animation-speed: 1s;
$animation-speed--hover: 0.2s;
$body-padding-lg: 40px;
$translate-half: translate(-50%, -50%);

$promo-width: 560px;
$promo-width-md: 480px;
$base-top-margin: 56px;
$terms-margin: 80px;

$screen-xs-min: 400px !default;
$screen-xs-max: ($screen-xs-min - 1) !default;

$screen-sm-min: 768px !default;
$screen-sm-max: ($screen-sm-min - 1) !default;

$screen-md-min: 1200px !default;
$screen-md-max: ($screen-md-min - 1) !default;

$screen-lg-min: 1600px !default;
$screen-lg-max: ($screen-lg-min - 1) !default;

$screen-xlg-min: 1920px !default;
$screen-xlg-max: ($screen-xlg-min - 1) !default;
