@import "../variables";

@mixin main-font {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin title-font {
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 100;
  font-style: normal;
}
