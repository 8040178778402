@import "./../../variables";
@import "./../../mixins";

// Функция для применения полифила gap
@mixin apply-gap-polyfill($gap, $direction: row) {
  & > * {
    margin: if($direction == row, 0 $gap 0 0, 0 0 $gap 0);
  }
  & > *:last-child {
    margin: 0;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;

  @media (max-width: $screen-md-max) {
    flex-direction: column;
    padding: 0 22px;
  }

  &__column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .scroll-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    //gap: 13px;
    @include apply-gap-polyfill(13px, column);
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
    opacity: 1;
    visibility: visible;
    transition: $animation-speed;

    @media (max-width: $screen-lg-max) {
      //gap: 8px;
      @include apply-gap-polyfill(8px, column);
      bottom: 77px;
    }

    @media (max-width: $screen-md-max) {
      position: static;
      transform: none;
      order: 4;
      flex: 0.8;
    }

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }

    .scroll-down-text {
      @include main-font();
      font-size: 14px;
      line-height: 170%;
      text-align: center;
      letter-spacing: -0.03em;
      color: $main-color;
      transition: $animation-speed;
      position: relative;

      @media (max-width: $screen-lg-max) {
        font-size: 12px;
      }
    }

    &.hidden .scroll-down-text {
      opacity: 0;
      visibility: hidden;
    }

    .scroll-down-image {
      position: relative;
    }

    .scroll-down-image__bg {
      width: 28px;
      height: 40px;
      mask: url("../../../../public/images/scroll-down-bg.svg") no-repeat center;
      mask-size: cover;
      background: $main-color;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      position: relative;

      @media (max-width: $screen-lg-max) {
        width: 22.4px;
        height: 32px;
      }
    }

    .scroll-down-image__arrow {
      mask: url("../../../../public/images/scroll-down-arrow.svg") no-repeat center;
      mask-size: cover;
      background: $main-color;
      width: 14px;
      height: 14.5px;
      position: absolute;
      left: 7px;
      top: 6px;
      animation: scroll-down-animation 2s infinite;

      @media (max-width: $screen-lg-max) {
        left: 5.9px;
        top: 5px;
        width: 10.74px;
        height: 11.2px;
      }
    }

    &.hidden .scroll-down-image__arrow {
      //animation: none;
      //opacity: 0;
      //visibility: hidden;
    }
  }

  .navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    position: absolute;
    width: 24px;
    //gap: 80px;
    @include apply-gap-polyfill(80px, column);
    left: 50%;
    top: 50%;
    transform: $translate-half;

    @media (max-width: $screen-lg-max) {
      //gap: 40px;
      @include apply-gap-polyfill(40px, column);
    }

    @media (max-width: $screen-md-max) {
      order: 2;
      transform: none;
      position: relative;
      left: 0;
      top: 0;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 16px;
      @include apply-gap-polyfill(40px, row);
    }

    .ellipse {
      border-radius: 100%;
      width: 24px;
      height: 24px;
      border: 3px solid $gray-color;
      background: $bg-color;
      position: relative;
      z-index: 1;

      @media (max-width: $screen-lg-max) {
        width: 16px;
        height: 16px;
      }

      &.active {
        background: $main-color;
        border-color: $main-color;
      }

      &:not(.active) {
        cursor: pointer;
      }
    }

    .line {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      border-right: 1px dashed $gray-color;
      height: 100%;

      @media (max-width: $screen-md-max) {
        width: 100%;
        height: 1px;
        top: 50%;
        left: 50%;
        border-right: none;
        border-bottom: 1px dashed $gray-color;
        transform: translate(-50%, -50%);
      }
    }
  }

  .slide {
    position: absolute;
    left: calc(50% - #{$promo-width} / 2);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $promo-width;
    opacity: 0;
    will-change: transform, opacity;

    @media (max-width: $screen-xlg-max) {
      left: calc(50% - #{$promo-width} / 2.15);
    }

    @media (max-width: $screen-lg-max) {
      max-width: $promo-width-md;
      left: calc(50% - #{$promo-width} / 3);
    }

    @media (max-width: $screen-md-max) {
      left: 0;
      max-width: 100%;
    }

    &.active {
      opacity: 1;
    }

    &:not(.active) {
      pointer-events: none;
    }

    .text-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      //gap: 40px;
      @include apply-gap-polyfill(40px, column);
      width: 100%;

      @media (max-width: $screen-lg-max) {
        //gap: 24px;
        @include apply-gap-polyfill(24px, column);
      }

      @media (max-width: $screen-md-max) {
        align-items: center;
      }

      .title {
        @include title-font();
        font-style: normal;
        font-size: 48px;
        line-height: 120%;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @media (max-width: $screen-lg-max) {
          font-size: 40px;
        }

        @media (max-width: $screen-md-max) {
          align-items: center;
          font-size: 30px;
        }
      }

      .highlight-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 16px 2px;
        background: $main-color;
        border-radius: 4px;
        color: $bg-color;
      }
    }

    .description {
      width: 100%;
      @include main-font();
      font-size: 14px;
      line-height: 170%;
      letter-spacing: -0.03em;
      color: #FFFFFF;
      max-width: $promo-width;

      @media (max-width: $screen-md-max) {
        text-align: center;
      }
    }

    .list-items {
      @include main-font();
      font-size: 14px;
      line-height: 170%;
      letter-spacing: -0.03em;
      color: #FFFFFF;
      max-width: $promo-width;
      margin-top: 40px;
      padding-left: 0;

      li {
        margin-left: 20px;
        box-sizing: border-box;
      }
    }

    .primary-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 60px;
      box-sizing: border-box;
      background: $main-color;
      border-radius: 2px;
      @include main-font();
      font-weight: 600;
      font-size: 14px;
      line-height: 170%;
      letter-spacing: -0.03em;
      color: $bg-color;
      text-decoration: none;
      transition: $animation-speed--hover;

      @media only screen and (hover: hover) {
        &:hover {
          background: $pure-white;
        }
      }

      @media (max-width: $screen-lg-max) {
        height: 40px;
      }
    }

    .secondary-button {
      @extend .primary-button;
      padding: 16px 50px;
      background: transparent;
      border: 1px solid $main-color;
      border-radius: 2px;
      color: $main-color;

      @media only screen and (hover: hover) {
        &:hover {
          background: transparent;
          color: $pure-white;
          border-color: $pure-white;
        }
      }
    }
  }
}

.logo-container {
  @media (max-width: $screen-md-max) {
    flex: 1;
    order: 1;
    position: relative;
    z-index: 1;
  }
}

.navigation-container {
  @media (max-width: $screen-md-max) {
    order: 3;
    flex: 2;
    overflow: visible;
  }
}

.logo {
  width: 300px;
  height: 135.484px;
  background: url("../../../../public/images/logo.png") no-repeat center;
  background-size: cover;

  @media (max-width: $screen-lg-max) {
    width: 220px;
    height: 99.355px;
  }
}

.custom-animate {
  &__inDefault {
    opacity: 1 !important;
  }
  &__inUp {
    animation: move-up-and-show-large $animation-speed;
    @media (max-width: $screen-md-max) {
      animation: move-up-and-show-small $animation-speed;
    }
    animation-fill-mode: forwards;
  }

  &__outUp {
    animation: move-up-and-hide-large $animation-speed 1;
    @media (max-width: $screen-md-max) {
      animation: move-up-and-hide-small $animation-speed 1;
    }
    opacity: 0;
  }

  &__inDown {
    animation: move-down-and-show-large $animation-speed;
    @media (max-width: $screen-md-max) {
      animation: move-down-and-show-small $animation-speed;
    }
    animation-fill-mode: forwards;
  }

  &__outDown {
    animation: move-down-and-hide-large $animation-speed 1;
    @media (max-width: $screen-md-max) {
      animation: move-down-and-hide-small $animation-speed 1;
    }
    opacity: 0;
  }
}

.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  //gap: 20px;
  @include apply-gap-polyfill(20px, row);

  @media (max-width: $screen-md-max) {
    //gap: 16px;
    @include apply-gap-polyfill(16px, row);
  }

  &__button {
    font-size: 0;
    color: transparent;
    display: inline-block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    text-decoration: none;

    @media (max-width: $screen-md-max) {
      width: 32px;
      height: 32px;
    }

    &--google {
      background-image: url(../../../../public/images/google.svg)
    }
    &--instagram {
      background-image: url(../../../../public/images/instagram.svg)
    }
    &--apple {
      background-image: url(../../../../public/images/apple.svg)
    }
    &--fb {
      background-image: url(../../../../public/images/fb.svg)
    }
    &--twitter {
      background-image: url(../../../../public/images/twitter.svg)
    }
    &--youtube {
      background-image: url(../../../../public/images/youtube.svg)
    }
  }
}

.partner {
  @media (max-width: $screen-md-max) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 380px;
    margin: 0 auto;
  }

  &__image {
    width: 100%;
    height: auto;
    max-width: 284.373px;
    margin: 40px 0;

    @media (max-width: $screen-lg-max) {
      margin: 32px 0;
      max-width: 200px;
    }

    @media (max-width: $screen-md-max) {
      margin: 32px 0;
      max-width: 180px;
    }
  }

  &__title {
    color: $pure-white;
    text-align: center;
    @include title-font();
    font-size: 20px;
    line-height: 120%; /* 24px */
  }

  &__list {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    //gap: 50px;
    @include apply-gap-polyfill(50px, row);
    margin-top: 24px;

    @media (max-width: $screen-lg-max) {
      margin-top: 18px;
      //gap: 10px 26px;
      @include apply-gap-polyfill(26px, row);

      & > :nth-child(1) {
        height: 16px;
      }

      & > :nth-child(2) {
        height: 12px;
      }

      & > :nth-child(3) {
        height: 18px;
      }

      & > :nth-child(4) {
        height: 7px;
      }
    }

    @media (max-width: $screen-md-max) {
      flex-wrap: wrap;
      justify-content: center;
      margin: 10px -13px -6px;

      & > * {
        margin: 6px 13px !important;
      }
    }
  }

  .designed {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    //gap: 20px;
    margin: 0 -20px;
    width: calc(100% + 40px);

    &::before,
    &::after {
      margin: 0 20px;
      content: '';
      display: block;
      flex: 1;
      height: 1px;
      background-color: $gray-color;
    }
  }
}

.stats {
  display: flex;
  justify-content: space-around;
  //gap: 40px;
  @include apply-gap-polyfill(40px, row);

  .stat {
    display: flex;
    flex-direction: column;
    align-items: center;

    .stat-value {
      color: $main-color;
      @include title-font();
      font-size: 48px;
      line-height: 120%;

      @media (max-width: $screen-lg-max) {
        font-size: 40px;
      }
    }

    .stat-label {
      color: var(--White, #FFF);
      @include main-font();
      font-size: 14px;
      line-height: 170%; /* 23.8px */
      letter-spacing: -0.42px;
    }
  }
}

.error-page-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90vw;
  max-width: 480px;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px 2px;
    background: $main-color;
    border-radius: 4px;
    @include title-font();
    font-size: 48px;
    line-height: 100%;
    color: $bg-color;
    width: fit-content;
    margin: 0 auto;

    @media (max-width: $screen-lg-max) {
      font-size: 40px;
    }

    @media (max-width: $screen-xs-max) {
      font-size: 30px;
    }
  }

  .subtitle {
    @include title-font();
    font-style: normal;
    font-size: 48px;
    line-height: 120%;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: $screen-lg-max) {
      font-size: 40px;
    }

    @media (max-width: $screen-xs-max) {
      font-size: 30px;
    }

    @media (max-width: $screen-md-max) {
      align-items: center;
    }
  }
}

.extra-margin--1 {
  @media (max-width: $screen-md-max) {
    margin-top: 16px;
  }

  @media (max-width: $screen-xs-max) {
    margin-top: 30px;
  }
}


.extra-margin--2 {
  @media (max-width: $screen-md-max) {
    margin-top: 50px;
  }

  @media (max-width: $screen-xs-max) {
    margin-top: 75px;
  }
}
