@keyframes scroll-down-animation {
  0%, 16.67% {
    transform: translateY(0);
  }
  33.33% {
    transform: translateY(10px);
  }
  50%, 66.67% {
    transform: translateY(10px);
  }
  83.33% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes move-up-and-hide-large {
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  99% {
    transform: translateY(calc(-80vh)) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: translateY(calc(-80vh)) scale(1.2);
    opacity: 0;
  }
}

@keyframes move-up-and-hide-small {
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  99% {
    transform: translateY(calc(-100vh)) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: translateY(calc(-100vh)) scale(1.2);
    opacity: 0;
  }
}

@keyframes move-up-and-show-large {
  0% {
    transform: translateY(calc(+80vh)) scale(1.2);
    opacity: 0;
  }
  1% {
    transform: translateY(calc(+80vh)) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

@keyframes move-up-and-show-small {
  0% {
    transform: translateY(calc(+100vh)) scale(1.2);
    opacity: 0;
  }
  1% {
    transform: translateY(calc(+100vh)) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

@keyframes move-down-and-hide-large {
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  99% {
    transform: translateY(calc(+80vh)) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: translateY(calc(+80vh)) scale(1.2);
    opacity: 0;
  }
}

@keyframes move-down-and-hide-small {
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  99% {
    transform: translateY(calc(+100vh)) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: translateY(calc(+100vh)) scale(1.2);
    opacity: 0;
  }
}

@keyframes move-down-and-show-large {
  0% {
    transform: translateY(calc(-80vh)) scale(1.2);
    opacity: 0;
  }
  1% {
    transform: translateY(calc(-80vh)) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

@keyframes move-down-and-show-small {
  0% {
    transform: translateY(calc(-100vh)) scale(1.2);
    opacity: 0;
  }
  1% {
    transform: translateY(calc(-100vh)) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}
