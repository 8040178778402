@import "variables";
@import "mixins";

:root {
  --app-screen-height: 100svh;
}

html,
body {
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  padding: $body-padding-lg;
  width: 100vw;
  min-height: 100svh;
  background: $bg-color;
  overflow: hidden;

  @media (max-width: $screen-lg-max) {
    padding: 32px;
  }

  @media (max-width: $screen-md-max) {
    padding: 0;
  }
}

#root {
  width: 100%;
  height: 100%;
}
