@import "./../variables";
@import "./../mixins";

.wrap-page {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  border: 1px solid #212326;

  @media (max-width: $screen-lg-max) {
    border: none;
  }

  @media (max-width: $screen-md-max) {
    overflow: hidden;
    border-radius: 0;
  }
}

.videoBG {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 40px;
  z-index: 0;

  @media (max-width: $screen-md-max) {
    border-radius: 0;
  }

  .videoElement {
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
}
