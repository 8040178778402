@import "./../variables";
@import "./../mixins";

.icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: $pure-white;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}
