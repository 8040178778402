@import "./../variables";
@import "./../mixins";

footer {
  z-index: 1;
  display: flex;
  row-gap: 20px;
  align-items: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: 24px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: $gray-color;
  @include main-font();
  font-size: 12px;
  line-height: 180%; /* 21.6px */
  letter-spacing: -0.36px;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 26px;

  @media (max-width: $screen-md-max) {
    font-size: 10px;
  }

  @media (max-width: 348px) {
    font-size: 8px;
  }

  a {
    text-decoration: none;
    color: $gray-color;

    @media only screen and (hover: hover) {
      &:hover {
        color: $pure-white;
      }
    }
  }
}

.terms + footer .terms-link,
.privacy + footer .privacy-link {
  display: none;
}

.footer-links {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media (max-width: 470px) {
    flex-direction: column;
    gap: 6px;
  }
}

.terms-link,
.privacy-link {

}
