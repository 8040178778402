@import "../variables";

[data-theme="light"] {
  --c-bg: #ffffff;
  --c-text: #16110D;
  --c-btn-shadow: #15100C;
  --c-header-border-color: #F5F5F5;
}

[data-theme="dark"] {
  --c-bg: #080F18;
  --c-text: #ffffff;
  --c-btn-shadow: #ffffff;
  --c-header-border-color: #88929F;
}
